import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleBand } from "@visx/scale";
import { Polygon } from "@visx/shape";
import { SeriesPoint } from "@visx/shape/lib/types";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import React from "react";
import { formattedNumber, formattedPercentage } from "../../utils/helpers";

type TooltipData = {
  bar: SeriesPoint<any>;
  color: string;
  height: number;
  index: number;
  key: string;
  percentage?: number;
  sides: number;
  value: any;
  width: number;
  x: number;
  y: number;
};

export type FunnelChartProps = {
  data: any;
  events?: boolean;
  height: number;
  margin?: {
    left: number;
    bottom: number;
    right: number;
    top: number;
  };
  width: number;
};

export type FunnelChartPolygonProps = {
  fill: string;
  key: string;
  percentage: number;
  sides: number;
  value: string | number;
  width: number;
};

export function FunnelChart({
  data,
  // @ts-ignore
  events = false,
  height,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  width,
}: FunnelChartProps) {
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "#EEE",
    color: "#000",
  };

  if (!data) {
    return null;
  }

  const polygonSize = 100;

  // scale
  const yScale = scaleBand<number>({
    domain: data.map((_: any, i: number): number => i),
    padding: 0.5,
  });

  const xScale = scaleBand<number>({
    domain: data.map((_: any, i: number): number => i),
    padding: 0.5,
  });

  let tooltipTimeout: number;

  const {
    hideTooltip,
    showTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
  } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  if (width < 10) return null;

  yScale.rangeRound([0, height - margin.top - margin.bottom - 10]);
  xScale.rangeRound([0, polygonSize]);

  const centerX = (width - margin.left - margin.right) / 2;

  return (
    <>
      <svg width={width} height={height} ref={containerRef}>
        <rect width={width} height={height} fill={"#FFF"} />
        {data.map((polygon: any, i: number) => (
          <React.Fragment key={`polygon-fragment-${polygon.key}-${i}`}>
            <Group
              key={`polygon-group-a-${polygon.key}-${i}`}
              top={(yScale(i) || 0) + polygonSize / 2}
              left={margin.left + centerX}
            >
              <Polygon
                transform={`scale(${
                  polygonSize / Number((xScale(i || 0) || 0) + 16)
                }, 0.90)`}
                sides={polygon.sides}
                size={polygonSize}
                fill={polygon.fill}
                rotate={45}
                onMouseLeave={(event) => {
                  if (!window) return;
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                  event?.currentTarget?.style.setProperty("opacity", "1");
                  event?.currentTarget?.style.setProperty("cursor", "default");
                }}
                onMouseEnter={(event) => {
                  if (!window) return;
                  tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 300);
                  event?.currentTarget.style.setProperty("opacity", "1");
                  event?.currentTarget.style.setProperty("cursor", "default");
                }}
                onMouseMove={(event) => {
                  if (!event) return;
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  event?.currentTarget?.style.setProperty("cursor", "pointer");
                  event?.currentTarget?.style.setProperty("opacity", "0.8");
                  // TooltipInPortal expects coordinates to be relative to containerRef
                  // localPoint returns coordinates relative to the nearest SVG, which
                  // is what containerRef is set to in this example.
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: polygon,
                    tooltipTop: eventSvgCoords?.y,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              />
              <Text
                width={width}
                textAnchor="middle"
                fontSize="clamp(0.65rem, 2vw, 0.75rem)"
                verticalAnchor="middle"
                color={"white"}
                y={margin.top - 16}
                x={margin.left / centerX}
                pointerEvents={"none"}
              >
                {polygon.key}
              </Text>
            </Group>
            <Group
              key={`polygon-group-b-${polygon.key}-${i}`}
              top={(yScale(i) || 0) + polygonSize / 2}
              left={margin.left + centerX}
            >
              <Text
                y={margin.top + 16}
                width={width}
                textAnchor="middle"
                fontWeight={600}
                fontSize="clamp(0.80rem, 1vw, 1rem)"
                color={"white"}
                verticalAnchor="middle"
                pointerEvents={"none"}
              >
                {formattedNumber(polygon.value)}
              </Text>
            </Group>
            <Group
              key={`polygon-group-c-${polygon.key}-${i}`}
              top={(yScale(i) || 0) + polygonSize / 2}
              left={width - margin.left - 32 - 10}
            >
              <Text
                width={width}
                textAnchor="end"
                color={"black"}
                fontSize="clamp(0.75rem, 1vw, 0.75rem)"
                verticalAnchor="middle"
                pointerEvents={"none"}
              >
                {formattedPercentage(
                  1,
                  (Math.floor(Number(polygon.percentage)) || 0) / 100,
                )}
              </Text>
            </Group>
          </React.Fragment>
        ))}
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{formattedNumber(tooltipData.value)}</div>
          <div>
            {formattedPercentage(
              1,
              (Math.floor(Number(tooltipData.percentage)) || 0) / 100,
            )}
          </div>
        </TooltipInPortal>
      )}
    </>
  );
}
