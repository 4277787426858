import ParentSize from "@visx/responsive/lib/components/ParentSize";
import * as React from "react";
import { Flex, Grid, Text, Container } from "theme-ui";
import { theme } from "../../../themes/base";
import { formatCurrency, formatNumber } from "../../../utils";
import { BarStacks } from "../../Chart";
import { ErrorWidget } from "../../ErrorWidget";
import { SectionHeader } from "../../SectionHeader";
import { StyledCard } from "../../StyledCard";
import { Tooltip } from "../../Tooltip";

export type DashboardOverviewProps = {
  activeAccountsByMonth: any;
  activeAccountMonthlyTrend: any;
  monthlyPaymentTrends: any;
  paymentAmountsByMonth: any;
  paymentCountsByMonth: any;
  placementsMTD: any;
  scrollRef: React.RefObject<HTMLInputElement | null>;
};

export function DashboardOverview({
  activeAccountsByMonth,
  activeAccountMonthlyTrend,
  monthlyPaymentTrends,
  paymentAmountsByMonth,
  paymentCountsByMonth,
  placementsMTD,
  scrollRef,
}: DashboardOverviewProps) {
  return (
    <Container
      sx={{
        backgroundColor: theme.colors.lightBlue,
        scrollMarginTop: theme.layout.scrollMarginTop,
      }}
      ref={scrollRef}
      id="dashboard-overview"
    >
      <Grid>
        <SectionHeader>
          <Text variant="sectionTitle">Dashboard Overview</Text>
          <Tooltip
            position="bottom"
            accessibilityLabel="What is the Dashboard Overview?"
          >
            This Dashboard summarizes Placements, Payments, Engagement and other
            metrics at the Client level. All Month to Date (MTD) is from the
            first of the month through end of day yesterday. All Monthly data is
            from the first through midnight on the last calendar day of the
            month. The Delinquency Filters are 0 through 6. A few clients have
            more than 6 Delinquency buckets, however, for most clients, 1
            through 6 will be sufficient. Zero (0) Delinquency Bucket refers to
            Pre-Delinquent Accounts.
          </Tooltip>
        </SectionHeader>
      </Grid>

      <Grid
        sx={{
          p: 4,
          gridGap: 4,
          gridTemplateColumns: "repeat(4, 1fr)",
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        {/* CARD - ACTIVE ACCOUNTS BY MONTH */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!activeAccountsByMonth?.error ? (
              <Text variant="widgetheader">
                {formatNumber(activeAccountsByMonth?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Active Accounts (MTD)</Text>
          </Flex>
        </StyledCard>

        {/* CARD - PLACEMENTS MTD */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!placementsMTD?.error ? (
              <Text variant="widgetheader">
                {formatNumber(placementsMTD?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Placements MTD</Text>
          </Flex>
        </StyledCard>

        {/* CARD PAYMENT AMOUNTS BY MONTH */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!paymentAmountsByMonth?.error ? (
              <Text variant="widgetheader">
                {formatCurrency(paymentAmountsByMonth?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Payments ($) MTD</Text>
          </Flex>
        </StyledCard>

        {/* CARD - PAYMENT COUNTS BY MONTH */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!paymentCountsByMonth?.error ? (
              <Text variant="widgetheader">
                {formatNumber(paymentCountsByMonth?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Payment Count (MTD)</Text>
          </Flex>
        </StyledCard>
      </Grid>
      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          gridAutoFlow: "row",
          paddingTop: 0,
          gridTemplateColumns: `repeat(1, 1fr)`,
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {/* CARD - ACTIVE ACCOUNT MONTHLY TREND */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Active Account Monthly Trend
            </Text>
            {!activeAccountMonthlyTrend?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={activeAccountMonthlyTrend?.value}
                      barColor={theme.colors.primary}
                      lineColor={theme.colors.primary}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>
      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          gridAutoFlow: "row",
          paddingTop: 0,
          gridTemplateColumns: `repeat(1, 1fr)`,
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {/* CARD - MONTHLY PAYMENT TRENDS */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Monthly Payment Trend
            </Text>
            {!monthlyPaymentTrends?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={monthlyPaymentTrends?.value?.all}
                      xUnit="currency"
                      yUnit="currency"
                      barColor={theme.colors.extendedGreen}
                      lineColor={theme.colors.primary}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>
    </Container>
  );
}
