import ParentSize from "@visx/responsive/lib/components/ParentSize";
import * as React from "react";
import { Flex, Grid, Text } from "theme-ui";
import { theme } from "../../../themes/base";
import { BarChart, HorizontalBars } from "../../Chart";
import { ErrorWidget } from "../../ErrorWidget";
import { SectionHeader } from "../../SectionHeader";
import { StyledCard } from "../../StyledCard";
import { Tooltip } from "../../Tooltip";

export type DelinquencyMetricsProps = {
  accountsByDelinquencyBucket: any;
  accountsByDelinquencyBucketMTD: any;
  scrollRef: React.RefObject<HTMLInputElement | null>;
};

export function DelinquencyMetrics({
  accountsByDelinquencyBucket,
  accountsByDelinquencyBucketMTD,
  scrollRef,
}: DelinquencyMetricsProps) {
  return (
    <div
      style={{
        backgroundColor: theme.colors.lightBlue,
        scrollMarginTop: theme.layout.scrollMarginTop,
      }}
      ref={scrollRef}
      id="delinquency-metrics"
    >
      <Grid>
        <SectionHeader>
          <Text variant="sectionTitle">Delinquency Metrics</Text>
          <Tooltip
            position="bottom"
            accessibilityLabel="What are Delinquency Metrics?"
          >
            Delinquency is calculated based on the lowest Delinquency cycle of
            an account in a given month.
          </Tooltip>
        </SectionHeader>
      </Grid>

      <Grid
        columns={[2, "1fr 2fr"]}
        sx={{
          gridGap: 4,
          p: 4,
          gridAutoFlow: "row",
          "@media screen and (max-width: 96em)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {/* CARD - ACCOUNTS BY DELINQUENCY BUCKET (MTD) */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Accounts by Delinquency Bucket (MTD)
            </Text>
            {!accountsByDelinquencyBucketMTD?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarChart
                      height={height}
                      width={width}
                      data={accountsByDelinquencyBucketMTD?.value}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>

        {/* CARD - ACCOUNTS BY DELINQUENCY BUCKET 6 MONTH TREND */}
        <StyledCard
          sx={{
            height: "400px",
            overflow: "hidden",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Accounts by Delinquency Bucket in the Past 6 Months
            </Text>
            {!accountsByDelinquencyBucket?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <HorizontalBars
                      height={height}
                      width={width}
                      data={accountsByDelinquencyBucket?.value?.all}
                      xUnit="number"
                      yUnit="number"
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>
    </div>
  );
}
