import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import * as React from "react";
import { Box, Container, Text } from "theme-ui";

export function DataTable(props: any) {
  const [data, setData] = React.useState(() => [...props.data]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns: props.columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  });

  return (
    <Container
      sx={{
        overflow: "auto",
        scrollBehavior: "smooth",
        marginTop: "2rem",
        background: `
        linear-gradient(#FFF 33%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), #FFF 66%) 0 100%,
        radial-gradient(
          farthest-side at 50% 0,
          rgba(111, 111, 111, 0.5),
          rgba(0, 0, 0, 0)
        ),
        radial-gradient(
          farthest-side at 50% 100%,
          rgba(111, 111, 111, 0.5),
          rgba(0, 0, 0, 0)
          )
          0 100%;
      `,
        backgroundColor: "#FFF",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundSize: "100% 3rem, 100% 3rem, 100% 1rem, 100% 1rem",
        borderTop: "1px solid #CCC",
        height: "100%",
      }}
    >
      <Box
        as="table"
        sx={{
          borderCollapse: "collapse",
          tableLayout: "fixed",
          paddingTop: props.offsetTop,
          minWidth: "100%",
          outline: "2px solid lightgrey",
        }}
      >
        <Box
          as="thead"
          sx={{
            background: "white",
            position: "sticky",
            outline: "2px solid lightgray",
            top: 0,
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Box
                    as="th"
                    key={header.id}
                    sx={{
                      minWidth: header.colSpan,
                      borderBottom: "1px solid lightgray",
                      borderRight: "1px solid lightgray",
                      padding: "0.5rem",
                      textAlign: "left",
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <Text variant="thead">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Text>
                      </div>
                    )}
                  </Box>
                );
              })}
            </tr>
          ))}
        </Box>
        <Box
          as="tbody"
          sx={{
            marginTop: props.offsetTop,
          }}
        >
          {table.getRowModel().rows.map((row) => {
            return (
              <Box
                as="tr"
                key={row.id}
                sx={{
                  borderBottom: "1px solid lightgray",
                  borderRight: "1px solid lightgray",
                  padding: "0.5rem",
                  textAlign: "left",
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Box
                      as="td"
                      key={cell.id}
                      sx={{
                        padding: "0.5rem",
                        border: "1px solid lightgray",
                      }}
                    >
                      <Text variant="cell">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
}
