import ParentSize from "@visx/responsive/lib/components/ParentSize";
import * as React from "react";
import { Flex, Grid, Text } from "theme-ui";
import { theme } from "../../../themes/base";
import { formatPercentage, formatNumber } from "../../../utils";
import { DataTable } from "../..//DataTable";
import { FunnelChart, BarStacks } from "../../Chart";
import { ErrorWidget } from "../../ErrorWidget";
import { SectionHeader } from "../../SectionHeader";
import { StyledCard } from "../../StyledCard";
import { Tooltip } from "../../Tooltip";

export type EmailFunnelAndDeliveryMetricsProps = {
  communicationFunnelCurrentMonth: any;
  communicationFunnelPreviousMonth: any;
  accountEmailFunnelCurrentMonth: any;
  accountEmailFunnelPreviousMonth: any;
  smsOptOutRate: any;
  communicationRateMTD: any;
  communicationRatePreviousMonth: any;
  emailEngagementMonthlyTotal: any;
  emailUnsubscribeRate: any;
  maxEmailsSentToAccount: any;
  monthlyEmailCadence: any;
  monthlyEmailCadenceColumns: any;
  monthlyEmailEngagementColumns: any;
  scrollRef: React.RefObject<HTMLInputElement | null>;
  sendRate: any;
  sendRatePreviousMonth: any;
  smsEnabled: boolean;
};

export function EmailFunnelAndDeliveryMetrics({
  communicationFunnelCurrentMonth,
  communicationFunnelPreviousMonth,
  accountEmailFunnelCurrentMonth,
  accountEmailFunnelPreviousMonth,
  smsOptOutRate,
  communicationRateMTD,
  communicationRatePreviousMonth,
  emailEngagementMonthlyTotal,
  emailUnsubscribeRate,
  maxEmailsSentToAccount,
  monthlyEmailCadence,
  monthlyEmailCadenceColumns,
  monthlyEmailEngagementColumns,
  scrollRef,
  sendRate,
  sendRatePreviousMonth,
  smsEnabled = false,
}: EmailFunnelAndDeliveryMetricsProps) {
  const tableKey =
    monthlyEmailCadence?.value.length > 0
      ? monthlyEmailCadence?.value[0]?.BRAND_ID ||
        monthlyEmailCadence?.value[0]?.CLIENT_ID
      : "";
  return (
    <div
      style={{
        backgroundColor: theme.colors.lightBlue,
        scrollMarginTop: theme.layout.scrollMarginTop,
      }}
      ref={scrollRef}
      id="email-funnel-and-delivery-metrics"
    >
      <Grid>
        <SectionHeader>
          <Text
            sx={{
              "@media screen and (max-width: 30rem)": {
                maxWidth: "10rem",
              },
            }}
            variant="sectionTitle"
          >
            Email Funnel and Delivery Metrics
          </Text>
          <Tooltip
            position="bottom"
            accessibilityLabel="What are the Email Funnel and Delivery Metrics?"
          >
            <>
              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text>
                  Account Communication Funnel includes Email. Sent, Delivered
                  and Click metrics are standard metrics for Email.
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Active Accounts</Text>
                <Text>
                  Number of Unique Accounts that were available to work in the
                  month
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Accounts with a Send</Text>
                <Text>Number of Active Accounts that were sent an email</Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Accounts with a Deliver</Text>
                <Text>
                  Number of Active Accounts where an email was delivered
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Accounts with an Open</Text>
                <Text>
                  Number of Active Accounts where an email was opened by the
                  customer
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Accounts with a Click</Text>
                <Text>
                  Number of Active Accounts where an email was clicked by the
                  customer
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Send Rate</Text>
                <Text>Accounts with Send divided by Active Accounts</Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Delivery Rate</Text>
                <Text>
                  Accounts with a Deliver divided by Accounts with a Send
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column", paddingBottom: "1rem" }}>
                <Text sx={{ fontWeight: "bold" }}>Open Rate</Text>
                <Text>
                  Accounts with an Open divided by Accounts with a Deliver
                </Text>
              </Flex>

              <Flex sx={{ flexFlow: "column" }}>
                <Text sx={{ fontWeight: "bold" }}>Click Rate</Text>
                <Text>
                  Accounts with a Click divided by Accounts with a Deliver
                </Text>
              </Flex>
            </>
          </Tooltip>
        </SectionHeader>
      </Grid>

      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          gridAutoFlow: "row",
          gridTemplateColumns: `repeat(2, 1fr)`,
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {/* CARD - ACCOUNT COMMUNICATION FUNNEL (MTD) */}
        {communicationFunnelCurrentMonth?.value && (
          <StyledCard
            sx={{
              height: "600px",
              overflow: "hidden",
            }}
          >
            <Flex
              sx={{
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
                minHeight: "100%",
                textAlign: "center",
                height: "300px",
              }}
            >
              <Text
                variant="widgettitle"
                sx={{
                  position: "absolute",
                  top: 0,
                  zIndex: 1,
                }}
              >
                Account Communication Funnel (MTD)
              </Text>
              {!communicationFunnelCurrentMonth?.error ? (
                <ParentSize>
                  {({ width, height }) => {
                    return (
                      <FunnelChart
                        height={height}
                        width={width}
                        data={communicationFunnelCurrentMonth?.value}
                      />
                    );
                  }}
                </ParentSize>
              ) : (
                <ErrorWidget />
              )}
            </Flex>
          </StyledCard>
        )}

        {/* CARD - ACCOUNT COMMUNICATION FUNNEL (PRIOR MONTH) */}
        <StyledCard
          sx={{
            height: "600px",
            overflow: "hidden",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Account Communication Funnel (Prior Month)
            </Text>
            {!communicationFunnelPreviousMonth?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <FunnelChart
                      height={height}
                      width={width}
                      data={communicationFunnelPreviousMonth?.value}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>

      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          gridAutoFlow: "row",
          gridTemplateColumns: `repeat(2, 1fr)`,
          paddingTop: 0,
          "@media screen and (max-width: 64em)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {/* CARD - ACCOUNT EMAIL FUNNEL (MTD) */}
        {accountEmailFunnelCurrentMonth?.value && (
          <StyledCard
            sx={{
              height: "600px",
              overflow: "hidden",
            }}
          >
            <Flex
              sx={{
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
                minHeight: "100%",
                textAlign: "center",
                height: "300px",
              }}
            >
              <Text
                variant="widgettitle"
                sx={{
                  position: "absolute",
                  top: 0,
                  zIndex: 1,
                }}
              >
                Account Email Funnel (MTD)
              </Text>
              {!accountEmailFunnelCurrentMonth?.error ? (
                <ParentSize>
                  {({ width, height }) => {
                    return (
                      <FunnelChart
                        height={height}
                        width={width}
                        data={accountEmailFunnelCurrentMonth?.value}
                      />
                    );
                  }}
                </ParentSize>
              ) : (
                <ErrorWidget />
              )}
            </Flex>
          </StyledCard>
        )}

        {/* CARD - ACCOUNT EMAIL FUNNEL (PRIOR MONTH) */}
        <StyledCard
          sx={{
            height: "600px",
            overflow: "hidden",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Account Email Funnel (Prior Month)
            </Text>
            {!accountEmailFunnelPreviousMonth?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <FunnelChart
                      height={height}
                      width={width}
                      data={accountEmailFunnelPreviousMonth?.value}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>

      <Grid
        sx={{
          p: 4,
          gridGap: 4,
          gridTemplateColumns: `repeat(4, 1fr)`,
          paddingTop: 0,
          "@media screen and (max-width: 56em)": {
            gridTemplateColumns: `repeat(2, 1fr)`,
          },
        }}
      >
        {/* CARD - EMAIL UNSUBSCRIBE RATE */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!emailUnsubscribeRate?.error ? (
              <Text variant="widgetheader">
                {formatPercentage(2, emailUnsubscribeRate?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Email Unsubscribe Rate</Text>
          </Flex>
        </StyledCard>

        {/* CARD - SEND RATE (MTD) */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!sendRate?.error ? (
              <Text variant="widgetheader">
                {formatPercentage(1, sendRate?.value.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Send Rate (MTD)</Text>
          </Flex>
        </StyledCard>

        {/* CARD - COMMUNICATION RATE (MTD) */}
        <StyledCard sx={{ minHeight: "8rem" }}>
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
            }}
          >
            {!communicationRateMTD?.error ? (
              <Text variant="widgetheader">
                {formatPercentage(1, communicationRateMTD?.value)}
              </Text>
            ) : (
              <ErrorWidget />
            )}
            <Text variant="widgetbody">Communication Rate (MTD)</Text>
          </Flex>
        </StyledCard>

        {smsEnabled ? (
          <>
            {/* CARD - ACCOUNT SMS OPT OUT RATE */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!smsOptOutRate?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, smsOptOutRate?.value / 100)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">Account SMS Opt Out Rate</Text>
              </Flex>
            </StyledCard>

            {/* CARD - SEND RATE PRIOR MONTH */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!sendRatePreviousMonth?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, sendRatePreviousMonth?.value.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">Send Rate Prior Month</Text>
              </Flex>
            </StyledCard>

            {/* CARD - COMMUNICATION RATE PRIOR MONTH */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!communicationRatePreviousMonth?.error ? (
                  <Text variant="widgetheader">
                    {formatPercentage(1, communicationRatePreviousMonth?.value)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">Communication Rate Prior Month</Text>
              </Flex>
            </StyledCard>

            {/* CARD - MAX EMAILS SENT TO ACCOUNT (MTD) */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!maxEmailsSentToAccount?.error ? (
                  <Text variant="widgetheader">
                    {formatNumber(maxEmailsSentToAccount?.value.current)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">
                  Max Emails Sent to Account (MTD)
                </Text>
              </Flex>
            </StyledCard>
          </>
        ) : (
          <>
            {/* CARD - MAX EMAILS SENT TO ACCOUNT (MTD) */}
            <StyledCard sx={{ minHeight: "8rem" }}>
              <Flex
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  flexFlow: "column",
                  minHeight: "100%",
                  textAlign: "center",
                }}
              >
                {!maxEmailsSentToAccount?.error ? (
                  <Text variant="widgetheader">
                    {formatNumber(maxEmailsSentToAccount?.value.current)}
                  </Text>
                ) : (
                  <ErrorWidget />
                )}
                <Text variant="widgetbody">
                  Max Emails Sent to Account (MTD)
                </Text>
              </Flex>
            </StyledCard>
          </>
        )}
      </Grid>

      {!smsEnabled && (
        <Grid
          sx={{
            gridGap: 4,
            p: 4,
            gridAutoFlow: "row",
            gridTemplateColumns: `repeat(2, 1fr)`,
            paddingTop: 0,
            "@media screen and (max-width: 64em)": {
              gridTemplateColumns: `repeat(1, 1fr)`,
            },
          }}
        >
          <StyledCard sx={{ minHeight: "8rem" }}>
            <Flex
              sx={{
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
                minHeight: "100%",
                textAlign: "center",
              }}
            >
              {!sendRatePreviousMonth?.error ? (
                <Text variant="widgetheader">
                  {formatPercentage(1, sendRatePreviousMonth?.value.value)}
                </Text>
              ) : (
                <ErrorWidget />
              )}
              <Text variant="widgetbody">Send Rate Prior Month</Text>
            </Flex>
          </StyledCard>

          {/* CARD - COMMUNICATION RATE PRIOR MONTH */}
          <StyledCard sx={{ minHeight: "8rem" }}>
            <Flex
              sx={{
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
                minHeight: "100%",
                textAlign: "center",
              }}
            >
              {!communicationRatePreviousMonth?.error ? (
                <Text variant="widgetheader">
                  {formatPercentage(1, communicationRatePreviousMonth?.value)}
                </Text>
              ) : (
                <ErrorWidget />
              )}
              <Text variant="widgetbody">Communication Rate Prior Month</Text>
            </Flex>
          </StyledCard>
        </Grid>
      )}

      <Grid
        sx={{
          p: 4,
          paddingTop: 0,
          gridTemplateColumns: "1fr",
          gridAutoFlow: "row",
        }}
      >
        {/* CARD - MAX EMAILS SENT TO ACCOUNT PAST 6 MONTHS */}
        <StyledCard
          sx={{
            minHeight: "400px",
            gridColumnStart: "unset",
            gridColumnEnd: "unset",
            "@media screen and (max-width: 40em)": {
              gridColumnStart: "unset",
              gridColumnEnd: "unset",
            },
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "300px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Max Emails Sent to Account Past 6 months
            </Text>
            {!maxEmailsSentToAccount?.error &&
            maxEmailsSentToAccount?.value?.all ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <BarStacks
                      height={height}
                      width={width}
                      data={maxEmailsSentToAccount.value?.all}
                      numTicks={12}
                      barColor={theme.colors.primary}
                      lineColor={theme.colors.primary}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>

      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          paddingTop: 0,
          gridTemplateColumns: "1fr",
          gridAutoFlow: "row",
        }}
      >
        {/* CARD - EMAIL ENGAGEMENT MONTHLY STATISTICS */}
        <StyledCard
          sx={{
            minHeight: "400px",
            width: "100%",
            position: "relative",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              height: "400px",
              textAlign: "center",
              overflow: "scroll",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Email Engagement Monthly Statistics
            </Text>
            {!emailEngagementMonthlyTotal?.error &&
            emailEngagementMonthlyTotal?.value ? (
              <DataTable
                data={emailEngagementMonthlyTotal?.value}
                columns={monthlyEmailEngagementColumns}
                offsetTop={"2rem"}
                key={`${tableKey}-email-engagement`}
              />
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>

      <Grid
        sx={{
          gridGap: 4,
          p: 4,
          paddingTop: 0,
          gridTemplateColumns: "1fr",
          gridAutoFlow: "row",
        }}
      >
        {/* CARD - EMAIL COUNT MONTHLY STATISTICS */}
        <StyledCard
          sx={{
            minHeight: "400px",
            width: "100%",
            position: "relative",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              height: "400px",
              textAlign: "center",
              overflow: "scroll",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Email Count Monthly Statistics
            </Text>
            {!monthlyEmailCadence?.error && monthlyEmailCadence?.value ? (
              <DataTable
                data={monthlyEmailCadence?.value}
                columns={monthlyEmailCadenceColumns}
                offsetTop={"45px"}
                key={`${tableKey}-email-cadence`}
              />
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>
    </div>
  );
}
