import ParentSize from "@visx/responsive/lib/components/ParentSize";
import * as React from "react";
import { Flex, Grid, Text } from "theme-ui";
import { theme } from "../../../themes/base";
import { Bars, PieChart } from "../../Chart";
import { ErrorWidget } from "../../ErrorWidget";
import { SectionHeader } from "../../SectionHeader";
import { StyledCard } from "../../StyledCard";
import { Tooltip } from "../../Tooltip";

export type ChannelDistributionProps = {
  channelDistribution: any;
  channelDistributionMTD: any;
  scrollRef: React.RefObject<HTMLInputElement | null>;
};

export function ChannelDistribution({
  channelDistribution,
  channelDistributionMTD,
  scrollRef,
}: ChannelDistributionProps) {
  return (
    <div
      style={{ scrollMarginTop: theme.layout.scrollMarginTop }}
      ref={scrollRef}
      id="channel-distribution"
    >
      <Grid>
        <SectionHeader>
          <Text variant="sectionTitle">Channel Distribution</Text>
          <Tooltip
            position="bottom"
            accessibilityLabel="What is Channel Distribution?"
          >
            <Flex sx={{ flexFlow: "column" }}>
              <Text>
                Channel Distribution measures the number of accounts that fall
                into the following categories:
              </Text>
              <ul>
                <li>All channels</li>
                <li>Only SMS</li>
                <li>Only Email</li>
                <li>Only Email and SMS</li>
                <li>Only Email and VM</li>
                <li>Only SMS and VM</li>
                <li>Only VM</li>
                <li>No channels</li>
              </ul>
            </Flex>
          </Tooltip>
        </SectionHeader>
      </Grid>

      <Grid
        columns={[2, "1fr 2fr"]}
        sx={{
          gridGap: 4,
          p: 4,
          gridAutoFlow: "row",
          "@media screen and (max-width: 96em)": {
            gridTemplateColumns: `repeat(1, 1fr)`,
          },
        }}
      >
        {/* CARD - CHANNEL DISTRIBUTION (MTD) */}
        <StyledCard
          sx={{
            minHeight: "400px",
            overflow: "hidden",
            position: "relative",
            width: "100%",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              textAlign: "center",
              height: "400px",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Channel Distribution (MTD)
            </Text>
            {!channelDistributionMTD?.error ? (
              <ParentSize>
                {({ width }) => {
                  return (
                    <PieChart
                      height={350}
                      width={width}
                      data={channelDistributionMTD?.value}
                      mode="donut"
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>

        {/* CARD - CHANNEL DISTRIBUTION 6 MONTH TREND */}
        <StyledCard
          sx={{
            minHeight: "400px",
            width: "100%",
            position: "relative",
          }}
        >
          <Flex
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              minHeight: "100%",
              height: "400px",
              textAlign: "center",
            }}
          >
            <Text
              variant="widgettitle"
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 1,
              }}
            >
              Channel Distribution in the Past 6 Months
            </Text>
            {!channelDistribution?.error ? (
              <ParentSize>
                {({ width, height }) => {
                  return (
                    <Bars
                      height={height}
                      width={width}
                      data={channelDistribution?.value}
                    />
                  );
                }}
              </ParentSize>
            ) : (
              <ErrorWidget />
            )}
          </Flex>
        </StyledCard>
      </Grid>
    </div>
  );
}
